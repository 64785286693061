/**
*
* InnerWrapper
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import useDialog from 'containers/Dialog/useDialog.js';
import cx from 'utils/cx.js';
import FieldButtons from './FieldButtons.jsx';
import messages from './messages.js';

function InnerWrapper(props) {
  const { field, helpers, inline, onChange, children } = props;
  const { id, onEdit, onCancel, onDelete, onSubmit } = field;
  const { dlgClick } = useDialog();
  const { setFieldValue, initialValues, setFieldTouched, values, errors, isValid } = helpers;
  const value = values[id];

  function handleChange(val) {
    setFieldValue(id, val);
    if (onChange) {
      onChange(id, val);
    }
  }

  function handleEdit() {
    setFieldTouched(id, false);
    onEdit(id, value);
  }

  function handleCancel() {
    handleChange(initialValues[id]);
    onCancel();
  }

  function handleDelete() {
    dlgClick({ message: messages.delete }, onDelete);
  }

  function handleSubmit() {
    setFieldTouched(id, true);
    if (!isValid) {
      return;
    }
    onSubmit(id, value);
  }

  function showErrors() {
    if (typeof errors[id] !== 'object') {
      return errors[id];
    }
    return Object.values(errors[id]).map((err) => <div key={err}>{err}</div>);
  }

  const columnClass = cx('d-flex', 'flex-column', 'align-items-start', 'max-width-100', !onEdit && 'flex-grow-1');
  const controlClass = inline ? 'd-inline-flex' : 'd-flex align-items-start';
  return (
    <div className={controlClass}>
      <div className={columnClass}>
        {children}
        <Form.Control.Feedback type="invalid">{showErrors()}</Form.Control.Feedback>
      </div>
      <FieldButtons {...field} onEdit={onEdit && handleEdit} onCancel={onCancel && handleCancel} onSubmit={onSubmit && handleSubmit} onDelete={onDelete && handleDelete} />
    </div>
  );
}

InnerWrapper.propTypes = {
  field: PropTypes.object,
  children: PropTypes.node.isRequired,
  helpers: PropTypes.object,
  inline: PropTypes.bool,
  onChange: PropTypes.func,
};

export default InnerWrapper;
