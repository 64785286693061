/**
 *
 * DashboardMenu
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { ListGroupItem } from 'react-bootstrap';
import useModules from 'containers/User/useModules.js';
import { MODULES_PAYMENTS } from 'containers/User/constants.js';
import AvatarWithText from 'components/media/AvatarWithText/index.jsx';
import Icon2 from 'components/media/Icon2/index.js';
import makeAdminLink from 'utils/makeAdminLink.js';
import ExpandItem from '../ExpandItem.jsx';
import messages from '../messages.js';
import PathItem from '../PathItem.jsx';

function DashboardMenu(props) {
  const { expanded, onClick, isManager, role } = props;
  const modules = useModules();
  const { pathname } = useLocation();
  if (!isManager) {
    return null;
  }
  const adminLink = makeAdminLink(role);

  return (
    <>
      <ExpandItem onClick={onClick} expanded={expanded}>
        <AvatarWithText icon="ss-settings" active={pathname.startsWith('/admin')}>
          <FormattedMessage {...messages.management} />
        </AvatarWithText>
      </ExpandItem>
      {expanded && (
        <div className="list-group-indent">
          <PathItem to="/admin/users">
            <AvatarWithText icon="ss-user" active={pathname.startsWith('/admin/users')}>
              <FormattedMessage {...messages.users} />
            </AvatarWithText>
          </PathItem>
          <PathItem to="/admin/groups">
            <AvatarWithText icon="ss-users" active={pathname.startsWith('/admin/groups')}>
              <FormattedMessage {...messages.groups} />
            </AvatarWithText>
          </PathItem>
          <PathItem to="/admin/settings">
            <AvatarWithText icon="ss-settings" active={pathname.startsWith('/admin/settings')}>
              <FormattedMessage {...messages.settings} />
            </AvatarWithText>
          </PathItem>
          {modules.includes(MODULES_PAYMENTS) && (
            <PathItem to="/admin/payments">
              <AvatarWithText icon="ss-banknote" active={pathname.startsWith('/admin/payments')}>
                <FormattedMessage {...messages.paymentModule} />
              </AvatarWithText>
            </PathItem>
          )}
          <PathItem to="/admin/newsletters">
            <AvatarWithText icon="ss-newspaper" active={pathname.startsWith('/admin/newsletters')}>
              <FormattedMessage {...messages.newsletters} />
            </AvatarWithText>
          </PathItem>
          <ListGroupItem action href={adminLink} className="d-flex justify-content-between align-items-baseline">
            <AvatarWithText icon="ss-ellipsis-vertical">
              <FormattedMessage {...messages.more} />
            </AvatarWithText>
            <Icon2 className="ss-external-link fs-6" />
          </ListGroupItem>
        </div>
      )}
    </>
  );
}

DashboardMenu.propTypes = {
  expanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  role: PropTypes.object.isRequired,
  isManager: PropTypes.bool,
};

export default DashboardMenu;
