import { css } from 'styled-components';

const formMixin = css`
  .form-control-unstyled {
    padding: 0;
    border-color: transparent;
    box-shadow: none;
    border-radius: 16px;
    &.form-control-around.is-invalid {
      margin-left: -8px;
      margin-right: -8px;
      padding: 8px;
    }
    &.form-check {
      padding-left: 1.5rem;
    }
  }

  .form-check-input {
    font-size: 1rem;
  }

  .form-4 .form-group {
    margin-bottom: 0.945rem;
  }
`;

export default formMixin;
