import moment from 'moment/dist/moment'; // eslint-disable-line import/extensions
import { addDays, startOfDay, isSameYear, isSameDay, isSameMonth } from 'utils/date-fns/index.js';
import { ENDASH, HELLIP } from 'utils/characters.js';

const LONGDATE_REGEX = /^(\w+\W+)(.*(19|20)\d{2}\W+)(.*)$/;

updateLocales();

function timeFormat(date, tpl) {
  if (typeof date === 'object' && date.start) {
    return periodFormat(date.start, date.end, tpl, date.allDay);
  }
  return dropCurrentYear(moment(date).format(tpl));
}

export function periodFormat(p1, p2, orgTpl, allDay) {
  const d1 = new Date(p1);
  const d2 = p2 ? new Date(p2) : null;
  const m1 = moment(d1);
  const m2 = moment(d2);
  const sameDay = isSameDay(d1, d2);
  const tpl = sameDay ? orgTpl : orgTpl.replace(/(l{3})l/i, '$1');
  let s1 = dropTime(m1.format(tpl), allDay);
  if (m1.isSame(m2)) {
    return dropCurrentYear(s1);
  }
  // en-dash with spaces if space or hyphen in time string
  let sep = /[ -]/.test(s1) ? ` ${ENDASH} ` : ENDASH;
  if (!d2) {
    return `${dropCurrentYear(s1)}${sep}${HELLIP}`;
  }
  const tplHasTime = hasTime(tpl);
  const withoutTime = allDay || !tplHasTime;
  if (sameDay && withoutTime) {
    return dropCurrentYear(s1);
  }
  const timeOnly = testTimeOnly(tpl);
  if (!sameDay && timeOnly) {
    return withDay(d1, d2, tpl);
  }
  let s2 = dropTime(m2.format(tpl), allDay);
  if (s1 === s2) {
    return dropCurrentYear(s1);
  }
  if (sameDay && !timeOnly) {
    // Show only time for second time if same day
    s2 = m2.format('LT');
    sep = ENDASH;
  }
  if (isSameYear(d1, d2)) {
    if (withoutTime) {
      if (/l{2,3}/i.test(tpl) && isSameMonth(d1, d2)) {
        const dt1 = m1.get('date');
        const dt2 = m2.get('date');
        return dropCurrentYear(s1.replace(dt1, `${dt1}${ENDASH}${dt2}`));
      }
      s1 = dropYear(s1);
      s2 = dropCurrentYear(s2);
    } else if (isSameYear(d1, new Date())) {
      s1 = dropYear(s1);
      s2 = dropYear(s2);
    }
  }
  return `${s1}${sep}${s2}`;
}

function withDay(d1, d2, tpl) {
  const inWeek = addDays(startOfDay(d1), 7) > d2;
  const s1 = formatWithDate(d1, inWeek, tpl);
  const s2 = formatWithDate(d2, inWeek, tpl);
  return `${s1} ${ENDASH} ${s2}`;
}

function formatWithDate(d, inWeek, tpl) {
  const m = moment(d);
  const str = m.format('llll');
  const tStr = m.format(tpl);
  const match = str.match(LONGDATE_REGEX);
  const dStr = inWeek ? match[1] : match[2];
  return dropYear(`${dStr}${tStr}`);
}

export default timeFormat;

export function dropTime(str, flag) {
  return flag ? str.replace(/\W*\d{1,2}:.*$/, '') : str;
}

function dropYear(str) {
  return str.replace(/ +(19|20)\d{2}/, '');
}

function dropCurrentYear(str) {
  const re = new RegExp(`\\W+${moment().get('year')}`);
  return str.replace(re, '');
}

function hasTime(tpl) {
  return !/^l{1,2}$/i.test(tpl);
}

function testTimeOnly(tpl) {
  return /^LTS?$/.test(tpl);
}

export function updateLocales() {
  moment.updateLocale('en', {
    week: { dow: 1 },
  });
  moment.updateLocale('en-gb', {
    week: { dow: 1 },
  });
  moment.updateLocale('nl', {
    week: { dow: 1 },
    weekdaysShort: 'zo_ma_di_wo_do_vr_za'.split('_'),
    monthsShort: 'jan_feb_mrt_apr_mei_jun_jul_aug_sep_okt_nov_dec'.split('_'),
    longDateFormat: {
      llll: 'dd D MMM YYYY HH:mm',
    },
  });
}

export function dateString(date) {
  return date.toISOString().slice(0, 10);
}
