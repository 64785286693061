/**
*
* FieldButtons
*
*/

import React from 'react';
import PropTypes from 'prop-types';
import IconButton from 'components/interaction/IconButton/index.jsx';
import { useIntl } from 'react-intl';
import messages from './messages.js';

function FieldButtons(props) {
  const { onEdit, onCancel, onDelete, onSubmit, disabled } = props;
  const intl = useIntl();
  const title = onEdit && disabled ? intl.formatMessage(messages.editDisabled) : undefined;
  const buttons = [
    { handler: onEdit, iconClass: 'ss-draw opacity-50 text-primary', variant: 'link' },
    { handler: onDelete, iconClass: 'ss-trash opacity-50 auto-color', variant: 'link' },
    { handler: onSubmit, iconClass: 'ss-checkmark', variant: 'success', className: 'ms-3' },
    { handler: onCancel, iconClass: 'ss-delete text-muted opacity-75', variant: 'light', className: 'ms-3' },
  ];
  return buttons.map(({ handler, iconClass, variant, className }) => handler
   && (
     <IconButton
       key={iconClass}
       icon={iconClass}
       onClick={handler}
       variant={variant}
       className={className}
       size="sm"
       disabled={disabled}
       title={title}
     />
   ));
}

FieldButtons.propTypes = {
  onEdit: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  onSubmit: PropTypes.func,
  disabled: PropTypes.bool,
};

export default FieldButtons;
