/**
 *
 * RoleDisplay
 *
 */

import PropTypes from 'prop-types';

import { capitalize } from 'lodash-es';
import { ROLES_BY_ID } from 'containers/User/constants.js';
import { useIntl } from 'react-intl';
import messages from './UserMenu/messages.js';

function RoleDisplay(props) {
  const { type, capitalized } = props;
  const intl = useIntl();
  const text = intl.formatMessage(messages[ROLES_BY_ID[type]]);
  return capitalized ? capitalize(text) : text;
}

RoleDisplay.propTypes = {
  type: PropTypes.number,
  capitalized: PropTypes.bool,
};

export default RoleDisplay;
