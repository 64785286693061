/**
*
* Switch
*
*/

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import Switch from 'react-toggle-switch';
import 'react-toggle-switch/src/css/switch.css';
import { Button } from 'react-bootstrap';
import Spinner from 'components/Spinner/index.jsx';
import doNothing from 'utils/doNothing.js';

const DEFAULT_WIDTH = 48;
const SMALL_WIDTH = 35;

const SwitchButton = styled(Button)`
  &.btn {
    padding: 0;
    display: flex;
    align-content: center;
    position: relative;
    &.disabled.working,
    &[disabled].working,
    &.working .switch {
      cursor: wait;
    }
  }
  .spinner-container {
    position: absolute;
    width: ${DEFAULT_WIDTH}px;
    i {
      font-size: ${DEFAULT_WIDTH / 2}px;
    }
  }
  .switch-toggle {
    box-shadow: none;
  };
  .switch:not(.on) {
    background-color: ${(p) => p.theme.btnDefaultBorder};
    border-color: ${(p) => p.theme.btnDefaultBorder};
    .switch-toggle {
      border-color: ${(p) => p.theme.btnDefaultBorder};
    }
  }
  .switch.on {
    background-color: ${(p) => p.theme.brandSuccess};
    border-color: ${(p) => p.theme.brandSuccess};
    .switch-toggle {
      border-color: ${(p) => p.theme.brandSuccess};
    }
  }
  .switch {
      width: ${DEFAULT_WIDTH}px;
    }
  .switch.on .switch-toggle {
    left: 21px;
  }
  ${(p) => p.size.startsWith('sm') && css`
    .switch {
      width: ${SMALL_WIDTH}px;
      height: 20px;
      border-radius: 10px;
    }
    .spinner-container {
      position: absolute; 
      width: ${SMALL_WIDTH}px;
      i {
        font-size: ${SMALL_WIDTH / 2}px;
      }
    }
    .switch-toggle {
      width: 18px;
      height: 18px;
      border-radius: 9px;
    }
    .switch.on .switch-toggle {
      left: 15px;
    }
  `}
  .switch.disabled {
    opacity: 0.5;
  }
  & > span {
    padding: 2px 4px 0;
  }
`;

function MySwitch(props) {
  const {
    value, onChange, children, disabled, before, size, working, label, optimistic, className,
  } = props;
  const [newValue, setValue] = useState(value);
  useEffect(() => {
    if (!working) {
      setValue(value);
    }
  }, [value, working]);
  const action = value ? 'Disable' : 'Enable';
  const ariaLabel = label ? `${action} ${label}` : undefined;
  function handleClick() {
    if (optimistic) {
      setValue(!newValue);
    }
    onChange(!newValue);
  }
  const pending = value !== newValue;
  const enabled = !disabled && !pending;
  return (
    <SwitchButton variant="link" className={`${className} btn-switch${pending ? ' working' : ''}`} disabled={!enabled} size={size} onClick={handleClick} aria-label={ariaLabel}>
      { before && children }
      { working && !optimistic && <Spinner className="switch-spinner" /> }
      <Switch role="checkbox" aria-checked={newValue} onClick={doNothing} enabled={enabled} on={newValue} />
      { !before && children }
    </SwitchButton>
  );
}

MySwitch.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  children: PropTypes.node,
  before: PropTypes.bool,
  optimistic: PropTypes.bool,
  working: PropTypes.bool,
  label: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
};

MySwitch.defaultProps = {
  size: '',
};

export default MySwitch;
