import useStorage from 'utils/storage/useStorage.js';
import config from './index.js';

const FEATURE_NEVER = Symbol('Never use');
const FEATURE_LOCAL = Symbol('Use only on localhost');
const FEATURE_TEST = Symbol('Do not use in production');
const FEATURE_ALWAYS = Symbol('Always use');
const FEATURE_SETTING = Symbol('Use setting');

const productionFeatures = {
  userlane: FEATURE_ALWAYS,
};

function useHasFeature(key) {
  switch (productionFeatures[key]) {
    case FEATURE_NEVER:
      return false;
    case FEATURE_LOCAL:
      return config.env === 'local';
    case FEATURE_TEST:
      return config.env !== 'production';
    case FEATURE_ALWAYS:
      return true;
    case FEATURE_SETTING:
      // eslint-disable-next-line react-hooks/rules-of-hooks
      return useStorage(key, false)[0];
    default:
      // eslint-disable-next-line no-console
      console.warn('Feature not recognized', key);
      return false;
  }
}

export default useHasFeature;
