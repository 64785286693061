/*
 * CurrentUser Messages
 *
 * This contains all the text for the CurrentUser component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  parent: {
    id: 'app.components.UserMenu.parent',
    defaultMessage: 'parent',
  },
  employee: {
    id: 'app.components.UserMenu.employee',
    defaultMessage: 'employee',
  },
  student: {
    id: 'app.components.UserMenu.student',
    defaultMessage: 'student',
  },
  logout: {
    id: 'app.components.UserMenu.logout',
    defaultMessage: 'Log Out',
  },
  profile: {
    id: 'app.components.UserMenu.profile',
    defaultMessage: 'Update Profile',
  },
  settings: {
    id: 'app.common.settings',
    defaultMessage: 'Settings',
  },
  support: {
    id: 'app.components.UserMenu.support',
    defaultMessage: 'Support',
  },
  enterCode: {
    id: 'app.components.UserMenu.enterCode',
    defaultMessage: 'Add Role',
  },
  en: {
    id: 'app.components.UserMenu.en',
    defaultMessage: 'English',
  },
  nl: {
    id: 'app.components.UserMenu.nl',
    defaultMessage: 'Nederlands',
  },
  myRoles: {
    id: 'app.components.UserMenu.myRoles',
    defaultMessage: 'My Roles',
  },
  myAccount: {
    id: 'app.components.UserMenu.myAccount',
    defaultMessage: 'My Account',
  },
});
