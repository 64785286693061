/**
*
* PublishModal
*
*/

import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';
import { Button, ListGroup, Modal } from 'react-bootstrap';
import { useApi2 } from 'containers/Api2/index.js';
import useModules from 'containers/User/useModules.js';
import { MODULES_IMAGEPREFERENCES } from 'containers/User/constants.js';
import Icon2 from 'components/media/Icon2/index.js';
import ImageRestrictions from 'components/imageUse/ImageRestrictions/index.jsx';
import Switch from 'components/interaction/Switch/index.jsx';
import CopyButton from 'components/interaction/CopyButton/index.jsx';
import Input from 'components/tags/Input/index.jsx';
import { CHANNEL_PROPS, CHANNEL_EXTERNAL } from './constants.jsx';
import ExternalContacts from './ExternalContacts/index.jsx';
import messages from './messages.js';
import usePublishModal from './usePublishModal.js';

export { default as PublishedIcons } from './PublishedIcons.jsx';

const CHANNEL_LINK = 99;

const Wrapper = styled(Modal)`
  &.modal .modal-dialog {
    @media (min-width: 640px) {
        width: 500px;
        margin: 30px auto;
    }
  }
  .switch {
    margin-right: 20px;
  }
  i.channel-icon {
    margin-right: 15px;
    width: 24px;
    text-align: center;
    &.ss-users {
      margin-left: 68px;
    }
  }

  .btn-link:last-child {
    padding-right: 0;
  }

  h4 {
    margin: 0;
    flex-grow: 1;
    color: ${(p) => p.theme.textColor};
  }
`;

function PublishModal(props) {
  const { entity, item, onHide } = props;
  const channels = useApi2({ url: `${entity}/${item.id}/publish/channels`, noReport: [401] });
  const modules = useModules();
  const [showExternal, toggleExternal] = useReducer((f) => !f, false);
  const { handlePublish, handleLink, handleShareExternal, working } = usePublishModal(item);

  if (showExternal) {
    return <ExternalContacts postId={item.id} onChange={handleShareExternal} onHide={toggleExternal} />;
  }
  if (!channels?.list) {
    return null;
  }

  const hasImagePreferences = modules.includes(MODULES_IMAGEPREFERENCES);
  const channelList = channels.list.map((id) => ({
    ...channels[id],
    ...CHANNEL_PROPS[id],
    value: item.publishedChannels.includes(id),
    link: `communityposts/${item.id}/publish/channels/${id}/restrictions`,
  }));
  const { publicUrl } = item;
  const isPublic = Boolean(publicUrl);
  const externalMessage = item.externalContactsCount > 0
    ? { ...messages.shareExternalCount, values: { count: item.externalContactsCount } }
    : messages.shareExternal;
  return (
    <Wrapper show onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage {...messages.header} />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-mute">
          <FormattedMessage {...messages.intro} />
        </p>
        <ListGroup className="nopadding">
          {channelList.map((ch) => (
            <ListGroup.Item key={ch.id} className="px-0 border-0">
              <div className="d-flex align-items-baseline flex-wrap flex-sm-nowrap justify-content-end">
                {ch.id !== CHANNEL_EXTERNAL && <Switch value={ch.value} working={working[ch.id]} onChange={(val) => handlePublish(ch.id, val)} />}
                <Icon2 className={`${ch.icon} fs-1 channel-icon`} />
                <h4 className="flex-grow-1">{ch.label}</h4>
                {ch.id === CHANNEL_EXTERNAL ? (
                  <Button variant="link" className="text-primary-dark" onClick={toggleExternal}>
                    <FormattedMessage {...externalMessage} />
                  </Button>
                ) : <span>{ch.detail}</span> }
              </div>
              {item.externalContactsCounts}
              {ch.id !== CHANNEL_EXTERNAL && hasImagePreferences && !ch.imageUsagePreferences.disabled && (
                <div className="w-100 text-end"><ImageRestrictions {...ch.imageUsagePreferences} label={ch.label} link={ch.link} inModal /></div>
              )}
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex align-items-center w-100 flex-wrap">
          <Switch value={isPublic} working={working[CHANNEL_LINK]} onChange={handleLink} />
          <span className="flex-grow-1">
            <FormattedMessage {...messages.publicLink} />
          </span>
          {isPublic ? (
            <>
              <Input read-only defaultValue={publicUrl} className="me-2 small py-1 px-3" />
              <CopyButton variant="link" text={publicUrl} />
            </>
          ) : (
            <span>
              <FormattedMessage {...messages.notYet} />
            </span>
          )}
        </div>
      </Modal.Footer>
    </Wrapper>
  );
}

PublishModal.propTypes = {
  item: PropTypes.object.isRequired,
  entity: PropTypes.string.isRequired,
  onHide: PropTypes.func.isRequired,
};

export default PublishModal;
