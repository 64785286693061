function getValue(value, field) {
  if (field.as) {
    return value;
  }
  if (field.type === 'date' && value instanceof Date) {
    return value.toISOString().slice(0, 10);
  }
  return value ?? '';
}

export default getValue;
